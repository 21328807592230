import { navigate } from 'gatsby'
import { I18nContext, localizePath } from 'gatsby-plugin-i18n'
import { useContext, useEffect } from 'react'
import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import Screen from 'swp-components/lib/components/screen'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import CookiesConsent from '@/components/cookies-consent'
import Seo from '@/components/seo'
import useCoverPageDataLayer from '@/hooks/data-layers/use-cover-page'
import strapi from '@/strapi'

const CoverPage = ({ serverData }) => {
  let [seo, headerScript, coverPage] = strapi.parseContents(serverData.contents)

  let { locale, defaultLocale } = useContext(I18nContext)

  useEffect(() => {
    if (!coverPage) {
      navigate(localizePath('/', locale, defaultLocale))
    }
  })

  let { handleMessage, createScopeProps } = useCoverPageDataLayer()

  return (
    <DataLayerProvider handler={handleMessage}>
      <Seo seo={seo.coverpageSeo} script={headerScript.script} />
      {coverPage && (
        <DataLayerScope {...createScopeProps('Cover')}>
          <Screen.Cover
            cover={coverPage}
            color={coverPage.themePages === 'Citrine' ? 'citrine-theme' : null}
          />
        </DataLayerScope>
      )}
      <CookiesConsent />
    </DataLayerProvider>
  )
}

export default CoverPage

export const getServerData = async ({ pageContext }) => {
  let contents = await strapi.getContents(
    ['seo', 'header-script', 'coverpage'],
    pageContext.locale
  )

  return {
    props: { contents },
  }
}
