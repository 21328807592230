import usePageDataLayer from './use-page-data-layer'

const handlePageMessage = ({ selector, context }, pushMessage) => {
  if (/^Cover\/Cover.button#\d$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'cover',
      button: context.button,
    })
  }
}

const useCoverPageDataLayer = () => {
  let { createHandler, createScopeProps } = usePageDataLayer()
  let handleMessage = createHandler(handlePageMessage)
  return { handleMessage, createScopeProps }
}

export default useCoverPageDataLayer
